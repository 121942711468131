import Container from "@material-ui/core/Container"
import { graphql, PageProps } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { useTemplateType } from "../components/layout/context"
import NieuwsNav from "../components/nieuwsNav"
import NieuwsBuilder from "../components/pageBuilder/nieuwsBuilder"
import SEO from "../components/SEO"
import { NieuwsPaginaQuery } from "../__generated__/graphql-gatsby"

type IProps = PageProps<NieuwsPaginaQuery>

const NieuwsPage: React.FC<IProps> = ({ data: { next, previous, wpNieuws } }) => {
  const [, setTemplate] = useTemplateType()
  setTemplate("news")

  const { seo, uri, nieuws_fields, nieuwsInstellingen, featuredImage } = { ...wpNieuws }
  const imgData = featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData
  const altText = String(featuredImage?.node?.altText)
  return (
    <>
      <SEO
        title={seo?.title}
        description={seo?.metaDesc}
        ogTitle={seo?.opengraphTitle}
        ogDescription={seo?.opengraphDescription}
        img={{
          url: seo?.opengraphImage?.sourceUrl,
          alt: seo?.opengraphImage?.altText,
          width: seo?.opengraphImage?.mediaDetails?.width,
          height: seo?.opengraphImage?.mediaDetails?.height,
        }}
        siteName={seo?.opengraphSiteName}
        canonical={uri}
        isArticle
      />

      {imgData && !nieuwsInstellingen?.verbergheroafbeelding && (
        <Container disableGutters maxWidth="lg">
          <GatsbyImage image={imgData} alt={altText} />
        </Container>
      )}

      <NieuwsBuilder blocks={nieuws_fields?.pageBuilder} />
      <NieuwsNav prev={previous} next={next} />
    </>
  )
}

export default NieuwsPage

export const query = graphql`
  fragment OtherNieuws on WpNieuws {
    id
    uri
    title
    date(formatString: "D-M-YYYY")
    featuredImage {
      node {
        __typename
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(height: 216, width: 350, transformOptions: { cropFocus: CENTER })
          }
        }
      }
    }
    nieuws_fields {
      samenvatting
    }
  }

  fragment NieuwsTekst on WpNieuws_NieuwsFields_PageBuilder_Tekst {
    __typename
    buttonAlignment
    buttonKleur
    buttonLink {
      ...PostLinks
    }
    buttonText
    cta
    tekst
  }

  fragment NieuwsAfbeelding on WpNieuws_NieuwsFields_PageBuilder_Afbeelding {
    __typename
    maxWidth
    hasPadding
    afbeelding {
      __typename
      altText
      localFile {
        publicURL
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }

  fragment NieuwsYoutube on WpNieuws_NieuwsFields_PageBuilder_Youtube {
    __typename
    url
    title
    maxWidthPlayer
    maxHeightPlayer
    fullWidth
    fieldGroupName
    aspectRatio
  }

  fragment NieuwsFormulier on WpNieuws_NieuwsFields_PageBuilder_Formulier {
    __typename
    formulier {
      ...Form
    }
  }

  fragment NieuwsScroller on WpNieuws_NieuwsFields_PageBuilder_Scroller {
    __typename
    list {
      img {
        __typename
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(height: 200, placeholder: BLURRED)
          }
        }
      }
    }
  }

  fragment NieuwsBuilder on WpNieuws_NieuwsFields_PageBuilder {
    ... on WpNieuws_NieuwsFields_PageBuilder_Tekst {
      ...NieuwsTekst
    }
    ... on WpNieuws_NieuwsFields_PageBuilder_Afbeelding {
      ...NieuwsAfbeelding
    }
    ... on WpNieuws_NieuwsFields_PageBuilder_Youtube {
      ...NieuwsYoutube
    }
    ... on WpNieuws_NieuwsFields_PageBuilder_Formulier {
      ...NieuwsFormulier
    }
    ... on WpNieuws_NieuwsFields_PageBuilder_Scroller {
      ...NieuwsScroller
    }
  }

  fragment NieuwsFields on WpNieuws_NieuwsFields {
    pageBuilder {
      ...NieuwsBuilder
    }
  }

  query NieuwsPagina($id: String!, $next: String, $prev: String) {
    next: wpNieuws(id: { eq: $next }) {
      ...OtherNieuws
    }
    previous: wpNieuws(id: { eq: $prev }) {
      ...OtherNieuws
    }
    wpNieuws(id: { eq: $id }) {
      id
      uri
      seo {
        ...SEO
      }
      nieuwsInstellingen {
        verbergheroafbeelding
      }
      nieuws_fields {
        ...NieuwsFields
      }
      featuredImage {
        node {
          __typename
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                aspectRatio: 1.87
                width: 1280
                transformOptions: { cropFocus: CENTER }
              )
            }
          }
        }
      }
    }
  }
`
