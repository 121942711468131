import Typography from "@material-ui/core/Typography"
import React from "react"
import styled from "styled-components"
import { colorFromCms } from "../../theme/globalStyles"
import { OtherNieuwsFragment } from "../../__generated__/graphql-gatsby"
import Button from "../base/button"
import NieuwsCard from "./nieuwsCard"

interface IProps {
  prev: Maybe<OtherNieuwsFragment>
  next: Maybe<OtherNieuwsFragment>
}

const Wrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(4)}px;
  background: ${() =>
    `linear-gradient(to bottom,${colorFromCms("yellow", 0)} 5%, ${colorFromCms(
      "yellow",
      0.00001
    )} 22%, ${colorFromCms("yellow", 0.005)} 32%, ${colorFromCms(
      "yellow",
      0.01
    )} 42%, ${colorFromCms("yellow", 0.1)} 62%, ${colorFromCms("yellow")} 100%)`};
`

const CardWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`
const BtnWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: ${({ theme }) => theme.spacing(3)}px;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`

const NieuwsNav: React.FC<IProps> = ({ prev, next }) => {
  const prevImg = prev?.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData
  const nextImg = next?.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData
  return (
    <Wrapper>
      <Typography variant="h1" align="center" color="secondary" gutterBottom>
        Meer lezen?
      </Typography>
      <CardWrapper>
        {prevImg && (
          <NieuwsCard
            title={String(prev?.title)}
            snippet={prev?.nieuws_fields?.samenvatting}
            uri={String(prev?.uri)}
            img={prevImg}
            date={prev?.date}
          />
        )}
        {nextImg && (
          <NieuwsCard
            title={String(next?.title)}
            snippet={next?.nieuws_fields?.samenvatting}
            uri={String(next?.uri)}
            img={nextImg}
            date={next?.date}
          />
        )}
      </CardWrapper>
      <BtnWrapper>
        <Button color="blue" link="/nieuws/">
          Terug naar het nieuwsoverzicht
        </Button>
      </BtnWrapper>
    </Wrapper>
  )
}

export default NieuwsNav
